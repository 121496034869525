import React, { Component} from 'react';
import { Link } from 'react-router-dom';
import $ from 'jquery';

class RgpdDisclaimer extends Component {
  constructor(props){
    super(props);

    const rgpdConfig = JSON.parse(localStorage.getItem('rgpd-config')) || { analytics: true };

    // if(rgpdConfig.configSaved){}
    this.state = {
      gaInitDone : false,
      isRgpdConfigSaved: rgpdConfig,
      iSConfigureModalOpen: false,
      config : {
        configSaved: rgpdConfig.configSaved,
        acceptAll : rgpdConfig.acceptAll,
        analytics : rgpdConfig.acceptAll ? true : rgpdConfig.analytics,
      }
    }
  }

  acceptAll(){
    this.setState({
      iSConfigureModalOpen : false,
      config : {
        configSaved: true,
        acceptAll: true,
        analytics : true,
      }
    });

    
    setTimeout(() => {
      this.save();
    }, 600);
  }

  rejectAll(){
    this.setState({
      iSConfigureModalOpen : false,
      config : {
        configSaved: true,
        acceptAll: false,
        analytics : false,
      }
    });

    setTimeout(() => {
      this.save();
    }, 600);
  }

  configure(){
    this.setState({
      iSConfigureModalOpen : true
    });
  }

  back(){
    this.setState({
      iSConfigureModalOpen : false
    });
  }

  toggleAnalytics(){
    this.setState({
      config : {
        analytics: !this.state.config.analytics,
        acceptAll: this.state.config.acceptAll,
        configSaved: this.state.config.configSaved,
      }
    });
  }

  save(){
    
    this.setState({
      // gaInitDone: true,
      iSConfigureModalOpen : false,
      config : {
        analytics: this.state.config.analytics,
        acceptAll: this.state.config.acceptAll,
        configSaved: true,
      }
    });

    setTimeout(() => {
      localStorage.setItem('rgpd-config', JSON.stringify(this.state.config));


      // reload page
      // window.location.reload();

      // const { gaInitDone,  config } = this.state;
      // if(!gaInitDone){
      //   if((config.acceptAll)){
      //     window.consent.allowAll();
      //   } else if((config.analytics)){
      //     window.consent.allowOnlyAnalytics();
      //   } else {
      //     window.consent.allowOnlyNecessary();
      //   }
      // }
      
    }, 200);
  }

  render() {

    const { gaInitDone, iSConfigureModalOpen, config } = this.state;
    if(config.configSaved){
      if((config.acceptAll && !gaInitDone)){
        this.setState({ gaInitDone: true });
        window.consent.allowAll();
      }
      else if((config.analytics && !gaInitDone)){
        this.setState({ gaInitDone: true });
        window.consent.allowOnlyAnalytics();
      } else {
        if(!gaInitDone && !config.acceptAll && !config.analytics){
          this.setState({ gaInitDone: true });
          window.consent.allowOnlyNecessary();
        }
      }

      $('body').removeClass('no-scoll');
      return <></>;
    }

    $('body').addClass('no-scoll');
    return (
      <>
      
        <div className="rgpd-overlay">
        
          <div className="rgpd-modal">

            { iSConfigureModalOpen && (
              <button type="button" class="close" onClick={()=>{this.back()}}>
                <span aria-hidden="true">&times;</span>
              </button>
            )}

            <div className="rgpd-banner">
              <a class="brand" href="/">Longines Timing</a>
            </div>

            { iSConfigureModalOpen ? (
              <>
                <div className="rgpd-disclaimer__message">
                  <h3>Configure my cookies</h3>
                  <p>On this Website, we may use the following cookies, cookie categories and other technologies, but no spyware, for the indicated purposes and, if applicable, deployed by or with the assistance of the indicated partners:</p>
                </div>
                <ul class="list-group list-group-flush">
                  <li class="list-group-item">
                    <span className="label-wrapper">Strictly necessary cookies</span>
                    <label>
                      Required
                    </label>
                  </li>
                  <li className="list-group-item">
                  <span className="label-wrapper">Analytical cookies</span>
                    <label className="switch">
                      <input type="checkbox" class="default" checked={config.analytics} onClick={()=>{this.toggleAnalytics()}} />
                    <span className="slider round"></span>
                    </label>
                  </li>
                </ul>
                <div className="rgpd-disclaimer__action mt-5 d-flex justify-content-center">
                  <span className='btn btn-outline-primary btn-square btn-medium' onClick={()=>{this.save()}}>Save setting</span>
                  <span className='btn btn-sm btn-primary btn-square btn-medium' onClick={()=>{this.acceptAll()}}>Accept all</span>
                  <span className='btn btn-sm btn-primary btn-square btn-medium' onClick={()=>{this.rejectAll()}}>Reject all</span>
                </div>
              </>
            ) : (
              <>  
                <div className="rgpd-disclaimer__message">
                  <h3>Welcome to Longines Timing</h3>
                  <p>I agree that this Website uses cookies and similar technologies for providing me this Website and its functionalities, for obtaining insights about its use and for providing me relevant advertisements. </p>
                  <p>To find out more about cookies and how to manage them, please see our <Link className='text-primary' to="/privacy-policy">privacy policy</Link></p>
                </div>
                <div className="rgpd-disclaimer__action mt-5 d-flex justify-content-center">
                  <span className='btn btn-sm btn-primary btn-square btn-medium' onClick={()=>{this.acceptAll()}}>Accept all</span>
                  <span className='btn btn-sm btn-primary btn-square btn-medium' onClick={()=>{this.rejectAll()}}>Reject all</span>
                  <span className='btn btn-sm btn-primary btn-square btn-medium' onClick={()=>{this.configure()}}>Settings</span>
                </div>
              </>
            )}
         </div>
        </div>
      
      
{/* 
      <div className="rgpd-disclaimer">
        <div className="rgpd-disclaimer__inner container">
          <div className="row align-items-center">
            <div className="rgpd-disclaimer__message col-lg-6">
              I agree that this Website uses cookies and similar technologies for providing me this Website and its functionalities, for obtaining insights about its use and for providing me relevant advertisements. 
              To find out more about cookies and how to manage them, please see our <Link className='text-light' to="/privacy-policy">privacy policy</Link>
            </div>
            <div className="rgpd-disclaimer__action col-lg-6">
              <span className='btn btn-sm btn-outline-light btn-square mr-2' onClick={()=>{this.acceptAll()}}>ACCEPT ALL COOKIES</span>
              <span className='btn btn-sm btn-outline-secondary btn-square mr-2' onClick={()=>{this.configure()}}>CONFIGURE</span>
              <span className='btn btn-sm btn-outline-secondary btn-square' onClick={()=>{this.rejectAll()}}>REJECT ALL COOKIES</span>
            </div>
          </div>
        </div>
      </div> */}
      </>
    );
  }
}

export default RgpdDisclaimer
