import React, { Component} from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from "react-redux";
import $ from 'jquery';
import EventResult from '../components/EventResult';
import appActions from '../redux/actions/appActions';
import dataProcessor from '../dataProcessors/equestrianProcessor';
import { compare } from 'dom-compare';

let timeoutRefForLive;

const SportInfo = {
  sectionName : 'Equestrian',
  slug: 'equestrian'
}

const mapStateToProps = state => ({
    events: state.events.equestrian
  }
)

const mapDispatchToProps = dispatch => ({
    setAppSection: () => { dispatch(appActions.setAppSection({appSection: 'equestrian lite'}))},
  }
)

class EventResultCr extends Component {
  constructor(props){
    super(props);
    console.log('new EventResultCr');
    this.state = {
      loading: true,
      event_data: null,
    };
  }

  getEventNameFormUrl(url){
    var name;
    name = decodeURI(url.replace(/-/g,' '));
    return name;

  }

  loadProviderData(event){
    const { match } = this.props;
    const { year, eventId } = match.params;
    const reactUrlForEvent = '/equestrian/' + year + '/' + eventId;
    const { event_data } = this.state; 
    const last_data_string = event_data ? event_data[0] : document.createElement('div'); 

    if(match.path === '/equestrian/lite/:year/:eventId' || match.path === '/equestrian/lite/:year/:eventId/*'){
      this.props.setAppSection();
    }

    if(!event.event_url){
      return;
    }

    if(match.path === '/equestrian/:year/:eventId' || match.path === '/equestrian/lite/:year/:eventId'){
      let urlToDownload = event.event_url.replace('http:', 'https:');
      $.get({
        url: urlToDownload,
        cache: false,
      })
      .done((data)=>{
       // $('.ajax-wrapper').append(data);
       data = dataProcessor.processData(data, event, reactUrlForEvent);
       this.setState({
         loading : false,
         event_data: data
       });
      })
      .fail(()=>{
        console.log('Error while fetching data');
      });

    } else if (match.path === '/equestrian/:year/:eventId/*' || match.path === '/equestrian/lite/:year/:eventId/*') {
      var event_root_url_slipped =  event.event_url.split('/')
      var event_root_url = event_root_url_slipped.slice(0, event_root_url_slipped.length - 1).join('/') + '/' ;
      event_root_url = event_root_url.replace('http://results.hippodata.de','');

      const url =  event_root_url + match.params[0];
      // const url =  event_root_url + match.params.subs;
      $.get({
        url: 'https://results.hippodata.de' + url,
        cache: false,
      })
      .done((data)=>{
       data = dataProcessor.processData(data, event, reactUrlForEvent);
       this.setState({
        loading : false,
        event_data: data
      });

    //   console.log('data', data.html());
    //   console.log('last_data_string', last_data_string);

    //   let res_compare = compare(data[0], last_data_string, {
    //     stripSpaces: true,
    //     compareComments: false,
    //     collapseSpaces: true,
    //     normalizeNewlines: true
    //   });

    //   if(!res_compare.getResult()){
    //     console.log('REFRESH CONTENT');
    //     this.setState({
    //       loading : false,
    //       event_data: data
    //     });
    //   }
       
      });
    } else {

    }
  }

  componentDidMount(){
    // only work in redux events of the year has been fetch before mounting
    this.loadProviderData(this.getTargetEvent());
    window.scrollTo(0,0);
    console.log('EventResultCr did mount');
  }

  componentDidUpdate(prevProps) {
    const { match } = this.props;
    if( match.url !== prevProps.match.url){
      this.loadProviderData(this.getTargetEvent());
    }

    dataProcessor.closeCollapseAreasByDefaultExternal();
    dataProcessor.prepareAjaxPopup();
  }

  getTargetEvent(){
    const { match, events } = this.props;
    const year = match.params.year;
    const eventId = match.params.eventId;
    const eventListing = events[year] || [];

    var event;
    if(eventId.match(/^\$/)){
      var lookFor; 
      console.log(eventId, 'eventId')
      // rule for Lyon
      if(eventId === '$lyon'){
        console.log(eventListing, 'eventListing')
        lookFor = new RegExp(/1634744858/);
        // event = eventListing.find(({ event_url }) => event_url && event_url.match(lookFor)) || {};
        // return event;
        event = eventListing.find(({ id }) => id === "sacramento-international-rancho-murieta-ca") || {};
        console.log(event, 'event')
        return event;
      }
      
    } 

    event = eventListing.find(({ id }) => id === eventId) || {};
    return event;
  }

  render() {
    const { history, match } = this.props;
    const { event_data } = this.state;
    const event = this.getTargetEvent();

    window.pageTracking.sendPageView(match.url);

    if(!event_data){
      // reload if this component has been mounted before the redux store mapping
      this.loadProviderData(event);
    }

    // $(event_data).find('#content-wrapper').addClass('live');

    var css = $(event_data).find('link');
    $('section.event-detail').prepend(css);

    // handleLIve
    clearTimeout(timeoutRefForLive);
    if($(event_data).find('#content-wrapper').hasClass('live')){
      timeoutRefForLive = setTimeout(()=>{
        this.loadProviderData(event);
      },20000);
    }

    return (
      <EventResult SportInfo={SportInfo} history={history} event={event} event_data={event_data} />
    );
  }
}

const EventResultDecorated = withRouter(props => <EventResultCr {...props}/>);
const EventResultDecoratedRx = connect(mapStateToProps, mapDispatchToProps)(EventResultDecorated);

export default EventResultDecoratedRx
