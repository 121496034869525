import React, { Component } from 'react';
import { Switch, Route, withRouter } from 'react-router-dom';
import { connect } from "react-redux";
import EventCalendar from '../components/EventCalendar';
import appActions from '../redux/actions/appActions';
import actions from '../redux/actions/alpineskiingActions';
import AlpingskiingEventResultCr from '../containers/AlpineskiingEventResultCr';
import { ConquestCTA } from '../components/Cta';

const SportInfo = {
  sectionName : 'Alpine Skiing',
  slug: 'alpine-skiing'
}

const mapStateToProps = state => {
  return { events: state.events.alpineskiing };
};

const mapDispatchToProps = dispatch => ({
    setAppSection: () => { dispatch(appActions.setAppSection({appSection: 'alpine-skiing'}))},
    resetFilters: () => { dispatch(appActions.resetFilters())},
    appInLoading: (bool) => { dispatch(appActions.appInLoading(bool))},
    fetchListing: (year) => { dispatch(actions.fetchAlpineskiingListing(year))},
  }
)

class EventListingCr extends Component {

  fetchListingFromProvider(){
    const { match } = this.props;

    const year = match.params.year || this.getCurrentYearSeason() ;
    this.props.fetchListing(year);
  }


  componentWillMount(){
    this.props.resetFilters();
    sessionStorage.setItem('lti:lastVisitedSection', SportInfo.slug);
  }

  componentDidMount(){
    this.props.setAppSection();
    this.fetchListingFromProvider();
  }

  componentDidUpdate(prevProps) {
    const { match } = this.props;
    if(match.params.year !== prevProps.match.params.year){
      this.fetchListingFromProvider();
    }

    setTimeout(()=>{
      this.props.appInLoading(false);
    }, 500);
  }

  getCurrentYearSeason(){
    const currentYear = (new Date()).getFullYear();
    const currentYearSeason = (new Date()).getMonth() < 9 ? currentYear : currentYear + 1;
    return currentYearSeason;
  }

  render(){

    const { match, history, events} = this.props;
    const year = match.params.year || this.getCurrentYearSeason();
    const eventListing =  events[year] || [];

    window.pageTracking.sendPageView(match.url);

    return (
      <div className="container">
        <Switch>
          <Route path="/alpine-skiing/:year/:eventId/*" component={AlpingskiingEventResultCr} />
          <Route path="/alpine-skiing/:year/:eventId" component={AlpingskiingEventResultCr} />
          <Route path="/alpine-skiing/:year?" render={(props) => <EventCalendar {...props} events={eventListing} SportInfo={SportInfo} history={history} />} />
        </Switch>
      </div>
    )
  }
}

const AlpineSKiingEventListingDecorated = withRouter(props => <EventListingCr {...props}/>);
const AlpineSKiingEventListingDecoratedRx = connect(mapStateToProps, mapDispatchToProps)(AlpineSKiingEventListingDecorated);

export default AlpineSKiingEventListingDecoratedRx
