import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { connect } from "react-redux";
import Scheduled from '../containers/ScheduledCr';
import appActions from '../redux/actions/appActions';
import equestrianActions from '../redux/actions/equestrianActions';
import alpineskiingActions from '../redux/actions/alpineskiingActions';
import gymnasticsActions from '../redux/actions/gymnasticsActions';
import AppLink from '../components/AppLink';

import { CwgCTA } from '../components/Cta';

const SportHistory = () => (
  <div className="longines-sport-history">
    <Link to="/sport-history">
      <div className="longines-sport-history-inner">
        <div className="row">
          <div className="box-content col-md-6">
            <h3>Longines Sport History</h3>
            Longines’ long-lasting involvement in sports timekeeping started in 1878 with the first chronograph manufactured by the brand.
            Building on its in-house expertise, Longines has gradually developed a special relationship with the world of sport. It can count now many years of experience as official timekeeper of world championships, and as partner of international sports federations.
          </div>
          <div className="box-watch col-md-6">
            <iframe title="e-clock" src="https://www.longinestiming.com/clock/200x200/" width="200" height="200" scrolling="no" frameBorder="0" />
          </div>
        </div>
      </div>
    </Link>

  </div>
);

const mapDispatchToProps = dispatch => ({
    setAppSection: () => { dispatch(appActions.setAppSection({appSection: 'homepage'}))},
    appInLoading: (bool) => { dispatch(appActions.appInLoading(bool))},
    fetchAlpineskiingListing: (year) => { dispatch(alpineskiingActions.fetchAlpineskiingListing(year))},
    fetchEquestrianListing: (year) => { dispatch(equestrianActions.fetchEquestrianListing(year))},
    fetchGymnasticsListing: (year) => { dispatch(gymnasticsActions.fetchGymnasticsListing(year))},
  }
)

class Homepage extends Component {
  componentWillMount(){
    this.fetchListingFromProvider();
  }
  componentDidMount(){
    this.props.setAppSection();
    setTimeout(()=>{
      this.props.appInLoading(false);
    }, 900);
  }
  componentDidUpdate(prevProps) {
    setTimeout(()=>{
      this.props.appInLoading(false);
    }, 900);
  }
  fetchListingFromProvider(){
    const currentYear = (new Date()).getFullYear();
    const alpineskiingYearSeason =  (new Date()).getMonth() < 9 ? currentYear : currentYear + 1;
    this.props.fetchEquestrianListing(currentYear);
    this.props.fetchAlpineskiingListing(alpineskiingYearSeason);
    // this.props.fetchGymnasticsListing(currentYear);
  }
  render() {
    window.pageTracking.sendPageView('/');
    return (
      <div className="page">
        <div className="hero">
          <div className="container">
            <div className="row align-items-center">
              <div className="alpine-skiing col">
                <div className="hero-title d-flex justify-content-start">
                  <AppLink to="/alpine-skiing" className="stretched-link">
                    <div className="explore">
                      <span>Explore</span>
                    </div>
                    <h2>Alpine Skiing</h2>
                  </AppLink>
                </div>
              </div>
              <div className="equestrian col">
                <div className="hero-title d-flex justify-content-end">
                  <AppLink to="/equestrian" className="stretched-link">
                  <div className="explore">
                      <span>Explore</span>
                    </div>
                    <h2>Equestrian</h2>
                  </AppLink>
                </div>
              </div>
              <div className="gymnastics col">
                <div className="hero-title">
                  <AppLink to="/gymnastics" className="stretched-link">
                    <div className="explore">
                      <span>Explore</span>
                    </div>
                    <h2>Gymnastics</h2>
                  </AppLink>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="container">
          <div className="row">
            <div className="col-sm">
              <Scheduled title="Current"/>
            </div>
          </div>
          <div className="row">
            <div className="col-md-6">
              <Scheduled title="Past"/>
            </div>
            <div className="col-md-6">
              <Scheduled title="Upcoming" />
            </div>
          </div>

          <div className="row">
            <div className="col-sm-12">
              <SportHistory />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const HomepageRx = connect(null, mapDispatchToProps)(Homepage);

export default HomepageRx
